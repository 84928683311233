.ProjectTitle {
  margin: 0.5rem 3rem;
  font-size: 1.5rem;
  font-weight: 900;
}

.ProjectDescription {
  margin: 1rem;
  font-size: 1rem;
  font-family: "Rubik";
}

.PresentationTitle {
  margin: 1rem 1rem 1.5rem;
  font-size: calc(1.8 * min(calc(10px + 0.8vw), 20px));
  line-height: 1.1;
  font-weight: 900;
}

.PresentationDescription {
  margin: 5px;
  font-size: 1.1rem;
  font-family: "Rubik";
}

.GradientHighlight {
  background-image: linear-gradient(
    45deg,
    hsl(212deg 78% 52%) 0%,
    hsl(251deg 68% 70%) 50%,
    hsl(291deg 59% 68%) 100%
  );
  background-clip: text;
  color: transparent;
}

.Card {
  --card-color: #f1f1f1;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 800px;
  background-color: var(--card-color);
  border-radius: 10px;
  box-shadow: 0px 4px 10px 3px rgba(60, 60, 60, 0.3);
  padding: 1rem;
  margin: 1rem;
  position: relative;
}
