.Footer {
  color: rgb(49, 28, 60);
  font-size: 16px;
  margin: 20px 10px 10px;
  text-align: center;
}

.Footer a {
  color: inherit;
  text-underline-offset: 2px;
}

.Footer > * {
  margin-top: 4px;
}

.GithubProfile {
  font-family: "IBM Plex Mono", monospace;
}
