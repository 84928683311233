.Navbar {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 10px;
}

.MusaText {
  letter-spacing: -0.03em;
  margin: 0px 5px;
  font-weight: 600;
  font-size: 40px;
  font-family: "Poppins";
  color: rgb(20, 20, 20);
}

.MusaLogo {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.GithubButton {
  color: inherit;
}

.RefreshButton {
  font-size: 20px;
  font-weight: 600;
  color: #dfdfeb;
  background-color: #1b1c1d;
  border-radius: 20px;
  padding: 10px 50px;
  box-shadow: 2px 3px 5px 2px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  outline: inherit;
  align-self: end;
}
